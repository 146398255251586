<template>
    <div class="bg-logo">
        <h1 class="page-title">Les ateliers</h1>
        <br />
        <v-tabs vertical class="transparent d-none d-lg-flex">
            <v-tab> {{ workshopTexts.part1.title }} </v-tab>
            <v-tab> {{ workshopTexts.part2.title }} </v-tab>

            <v-tab-item>
                <v-card flat>
                    <v-card-text class="text-left">
                        <div v-html="workshopTexts.part1.content"></div>
                        <br />
                        <div class="d-flex d-lg-none justify-center">
                            <img src="../assets/img-2-small.jpg" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text class="text-left">
                        <div v-html="workshopTexts.part2.content"></div>
                        <br />
                        <div class="d-flex d-lg-none justify-center">
                            <img src="../assets/img-3-small.jpg" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>

        <v-expansion-panels class="d-flex d-lg-none">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{ workshopTexts.part1.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    v-html="workshopTexts.part1.content"
                    class="text-left flex-column pa-2"
                >
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{ workshopTexts.part2.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    v-html="workshopTexts.part2.content"
                    class="text-left flex-column pa-2"
                >
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import texts from '../assets/texts';
export default {
    data() {
        return {
            workshopTexts: texts.workshops,
        };
    },
};
</script>

<style scoped>
.img2 {
    width: 200px;
    position: fixed;
    top: 60%;
    left: 5%;
    transform: rotate(-5deg);
}
.img3 {
    width: 300px;
    position: fixed;
    top: 57%;
    left: 52%;
    transform: rotate(10deg);
}
</style>
