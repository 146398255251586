<template>
    <v-app>
        <!-- <v-card class="overflow-hidden" height="100%"> -->
        <v-app-bar
            app
            absolute
            color="primary"
            :height="navbarHeight"
            width="100%"
            dark
            src="./assets/app-bar-bg.jpg"
        >
            <v-container fluid class="d-none d-lg-flex">
                <v-row
                    ><v-col class="flex-grow-0 flex-shrink-0">
                        <router-link to="/"
                            ><v-img
                                alt="Logo Bébé Chou Massage"
                                class="align-stretch d-flex d-lg-none"
                                contain
                                :src="require('./assets/logo-w500.png')"
                                transition="scale-transition"
                                width="150"
                                centered
                        /></router-link>
                        <router-link to="/"
                            ><v-img
                                alt="Logo Bébé Chou Massage"
                                class="d-none d-lg-flex"
                                contain
                                :src="require('./assets/logo-w500.png')"
                                transition="scale-transition"
                                width="150"
                                centered /></router-link></v-col
                    ><v-col
                        class="flex-column text-center flex-grow-1 flex-shrink-0"
                    >
                        <h1 class="align-start app-title" width="100%">
                            Bébé Chou Massage
                        </h1>
                        <v-tabs
                            class="align-end"
                            centered
                            background-color="rgb(255,255,255,0.4)"
                            light
                            primary
                            show-arrows
                        >
                            <v-tab to="/qui-suis-je">Qui suis-je ?</v-tab>
                            <v-tab to="/a-propos-du-massage">Le massage</v-tab>
                            <v-tab to="/ateliers">Les ateliers</v-tab>
                            <v-tab to="/tarifs">Les tarifs</v-tab>
                            <v-tab to="/carte-cadeau">Bon cadeau</v-tab>
                            <v-tab to="/contact">Contactez-moi</v-tab>
                        </v-tabs>
                    </v-col></v-row
                >
            </v-container>
            <v-container fluid class="d-flex d-lg-none align-center">
                <v-app-bar-nav-icon
                    width="80px"
                    @click="drawer = true"
                ></v-app-bar-nav-icon>
                <h1
                    class="app-title app-title-small align-self-center mx-auto"
                    width="100%"
                    :style="'font-size: ' + appTitleFontSize"
                >
                    Bébé Chou Massage
                </h1>
                <div class="d-none d-sm-flex" style="width: 80px"></div>
            </v-container>
        </v-app-bar>
        <v-navigation-drawer v-model="drawer" absolute temporary width="170px">
            <router-link to="/"
                ><v-img
                    alt="Logo Bébé Chou Massage"
                    class="mx-auto"
                    contain
                    :src="require('./assets/logo-w500.png')"
                    transition="scale-transition"
                    width="150"
                    centered
            /></router-link>
            <v-list nav dense>
                <v-list-item-group
                    v-model="group"
                    active-class="deep-purple--text text--accent-4"
                >
                    <v-list-item to="/qui-suis-je">
                        <v-list-item-title>Qui suis-je ?</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/a-propos-du-massage">
                        <v-list-item-title>Le massage</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/ateliers">
                        <v-list-item-title>Les ateliers</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/tarifs">
                        <v-list-item-title>Les tarifs</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/carte-cadeau">
                        <v-list-item-title>Bon cadeau</v-list-item-title>
                    </v-list-item>

                    <v-list-item to="/contact">
                        <v-list-item-title>Contactez-moi</v-list-item-title>
                    </v-list-item>
                </v-list-item-group>
            </v-list>
        </v-navigation-drawer>
        <v-main height="100%">
            <!-- <v-sheet class="overflow-y-auto" height="100%"> -->
            <v-container fluid>
                <div class="background">
                    <div class="column">
                        <router-view />
                    </div>
                </div>
            </v-container>
            <!-- </v-sheet> -->
        </v-main>
        <!-- </v-card> -->
    </v-app>
</template>

<script>
export default {
    name: 'App',

    components: {},

    data: () => ({
        drawer: false,
    }),
    computed: {
        appTitleFontSize() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return '2rem';
                case 'sm':
                    return '3rem';
                case 'md':
                    return '3rem';
                case 'lg':
                    return '4rem';
                case 'xl':
                    return '4rem';
                default:
                    return '4rem';
            }
        },
        navbarHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 100;
                case 'sm':
                    return 100;
                case 'md':
                    return 100;
                case 'lg':
                    return 180;
                case 'xl':
                    return 180;
                default:
                    return 100;
            }
        },
        logoHeight() {
            switch (this.$vuetify.breakpoint.name) {
                case 'xs':
                    return 100;
                case 'sm':
                    return 100;
                case 'md':
                    return 100;
                case 'lg':
                    return 180;
                case 'xl':
                    return 180;
                default:
                    return 100;
            }
        },
    },
};
</script>

<style>
@font-face {
    font-family: 'BCM Title';
    font-style: normal;
    font-weight: 400;
    src: url('./assets/bcmtitle.ttf');
}
@font-face {
    font-family: 'Goudy Old Style Regular';
    src: url('./assets/goudy-old-style-regular.ttf');
}
.test {
    background-color: blueviolet;
}
html {
    overflow-y: auto !important;
    font-size: 18px !important;
}
.v-application {
    font-family: 'Goudy Old Style Regular', arial !important;
}
.full {
    height: calc(100% - 180px);
}
.app-title {
    font-family: 'BCM Title', arial;
    text-shadow: 4px 4px 4px #aaa;
    font-size: 4rem;
    /* padding-bottom: 20px; */
}
.page-title {
    font-family: 'BCM Title', arial;
    font-size: 2.5rem;
    text-shadow: 4px 4px 4px #aaa;
    text-align: center;
}
.container {
    padding: 0 !important;
    margin: 0 !important;
    height: 100%;
}
.background {
    height: 100%;
    text-align: center;
    padding-top: 1rem;
    background-color: #00bfff;
}
.column {
    /* height: 80%; */
    width: 80%;
    margin: 1rem auto;
    background-color: white;
    border-radius: 1rem;
    /* overflow-y: auto !important; */
}
.bg-logo {
    width: 100%;
    height: 100%;
    background: center no-repeat url('./assets/logo-light-w500.png');
    padding: 2rem;
}
.v-tab {
    font-weight: bold !important;
}
.theme--light.v-tabs-items {
    background-color: transparent !important;
}
.theme--light.v-tabs.transparent > .v-tabs-bar {
    background-color: transparent !important;
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active),
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-icon,
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) > .v-btn,
.theme--light.v-tabs > .v-tabs-bar .v-tab--disabled {
    color: black !important;
}
.theme--light.v-card {
    background-color: transparent !important;
    /* max-height: 100%; */
    /* overflow-y: auto !important; */
}
.v-card__text {
    font-size: 1rem !important;
    font-weight: normal !important;
    color: black !important;
}
::-webkit-scrollbar {
    width: 6px;
    height: 6px;
}
::-webkit-scrollbar-thumb {
    background: #0058ff;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #00bfff;
}
::-webkit-scrollbar-track {
    background: #ffffff;
    border-radius: 10px;
    box-shadow: inset 7px 10px 12px #9be4fb;
}
</style>
