module.exports = {
    massage: {
        part1: {
            title: 'A propos',
            content:
                "<p>\
                Le toucher est le premier sens par lequel votre bébé\
                entre en contact avec le monde.<br />\
                A travers le massage, vous communiquez avec votre\
                bébé, vous lui apportez douceur et tendresse.<br />\
                Grâce à des gestes simples, effectués calmement,\
                vous créez une forme de dialogue et de complicité et\
                un espace d'intimité qui n'appartiennent qu'à votre\
                enfant et à vous-même.\
            </p>",
        },
        part2: {
            title: 'Les bienfaits',
            content:
                "<p>\
                Les effets positifs du massage bébé sont reconnus\
                dans le monde. Des études scientifiques ont montré\
                leur bienfaits sur la santé et le développement de\
                l'enfant.<br />\
                Les massages bébé ont des effets positifs sur\
                l'ensemble de la famille.\
            </p>\
            <h3>Pour les bébés :</h3>\
            <p>\
                Permet une diminution du stress, moins de pleurs et\
                une totale détente.<br />\
                Permet de stimuler les différents systèmes de\
                l'organisme (respiratoire, circulatoire,\
                gastro-intestinal, immunitaire et nerveux).<br />\
                Permet de soulager de nombreux maux, les coliques,\
                les douleurs liées à la croissance, la constipation,\
                les douleurs dentaires, les bronches encombrées et\
                les sinus congestionnés.<br />\
                C'est aussi un instant privilégié de communication\
                et d'échange entre le parent et l'enfant qui\
                renforce le lien d'attachement.\
            </p>\
            <h3>Pour les parents :</h3>\
            <p>\
                C'est une occasion d'entrer en contact avec leur\
                enfant et lui manifester tout l'amour qu'ils lui\
                portent.<br />\
                Il apporte confiance, leur apprend à observer et à\
                mieux connaître leur enfant.<br />\
                Il est aussi un bon moyen pour lutter contre le\
                fameux « baby blues » en améliorant l’interaction\
                mère-enfant.<br />\
                Il permet aussi au deuxième parent de prendre sa\
                place et d'avoir des moments privilégiés avec son\
                bébé.\
            </p>\
            <h3>Pour la fratrie:</h3>\
            <p>\
                Renforce le lien, limite les rivalités en faisant\
                participer les frères et soeurs au massage.\
            </p>",
        },
        part3: {
            title: 'Les conditions',
            content:
                "<p>\
                Il faut choisir le bon moment et être à l’écoute de\
                bébé. Idéalement, le massage bébé se pratique lors\
                de l'éveil calme, à éviter lorsque bébé à faim, est\
                fatigué ou juste après un repas.<br />\
                C'est un moment de partage, le parent doit être\
                disponible physiquement et psychologiquement pour\
                donner le massage et le bébé prédisposé à le\
                recevoir, c'est la notion de permission.<br />\
                L'enfant doit être nu idéalement, allongé sur un\
                tapis au sol dans une pièce chauffée.\
            </p>",
        },
    },
    workshops: {
        part1: {
            title: '0-18 Mois',
            content:
                '<h3 class="text-center">Atelier d\'initation</h3>\
            <p>\
                Séance d\'environ 1h.<br />Il permet de vous faire\
                découvrir les massages pour bébé. Il se conclut par\
                quelques mouvements de yoga pour bébé pour apprendre\
                à bébé à s\'étirer en douceur et pour une meilleure\
                conscience corporelle.<br />Une fiche\
                d\'apprentissage vous est fournie à la fin de la\
                séance.\
            </p>\
            <h3 class="text-center">Atelier "soulager les maux"</h3>\
            <p>\
                Séance d\'environ 1h.<br />Permet de vous donner des\
                techniques de massage pour soulager les petits maux\
                de bébé tels que les maux de ventre, les petits\
                rhumes, et les douleurs dentaires. Avec en plus une\
                notion de réflexologie plantaire pour bébé.<br />Une\
                fiche d\'apprentissage vous est fournie à la fin de\
                la séance.\
            </p>\
            <h3 class="text-center">Atelier cycle "4 séances"</h3>\
            <p>\
                4 séances d\'environ 1h chacune espacée d\'environ 1\
                semaine.<br />Permet l\'apprentissage des mouvements\
                pour masser tout le corps de bébé, en intégrant\
                également les techniques pour soulager les maux de\
                bébé. Une huile de massage BIO pour bébé est offerte\
                en début de séance.<br />Un livret d\'apprentissage\
                vous est fourni à la fin de la séance.\
            </p>',
        },
        part2: {
            title: '18-36 Mois',
            content:
                "<h3 class=\"text-center\">Atelier bambin</h3>\
            <p>\
                Séance d'environ 1h.<br />Se présente sous forme de\
                différentes activités. L'apprentissage des\
                mouvements pour masser tout le corps de bébé. Des\
                comptines tactiles. Du yoga. De la manipulation\
                d'objets sensoriels.\
            </p>",
        },
    },
};
