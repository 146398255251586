import { render, staticRenderFns } from "./Workshops.vue?vue&type=template&id=063bd5e0&scoped=true&"
import script from "./Workshops.vue?vue&type=script&lang=js&"
export * from "./Workshops.vue?vue&type=script&lang=js&"
import style0 from "./Workshops.vue?vue&type=style&index=0&id=063bd5e0&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "063bd5e0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
installComponents(component, {VCard,VCardText,VExpansionPanel,VExpansionPanelContent,VExpansionPanelHeader,VExpansionPanels,VTab,VTabItem,VTabs})
