<template>
    <div class="home">
        <v-img
            to="/"
            alt="Logo Bébé Chou Massage"
            class="shrink mx-auto"
            contain
            :src="require('../assets/logo.png')"
            transition="scale-transition"
            width="400px"
            centered
        />
        <h2>Ateliers massages bébés<br />0-36 mois</h2>
    </div>
</template>

<script>
export default {
    name: 'Home',
};
</script>

<style scoped>
.home {
    text-align: center;
}
</style>
