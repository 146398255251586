<template>
    <div class="bg-logo">
        <h1 class="page-title">Les tarifs</h1>
        <br />
        <!-- <div class="rounded-lg bg-sand"> -->
        <table class="mx-auto rounded-lg bg-sand">
            <tr>
                <td>Séance individuelle</td>
                <td>55€ / Atelier</td>
            </tr>
            <tr>
                <td>Séance en groupe (4 enfants maxi)</td>
                <td>45€ / Atelier</td>
            </tr>
            <tr>
                <td>Cycle 4 séances individuelles</td>
                <td>190€ les 4 séances</td>
            </tr>
            <tr>
                <td>Cycle 4 séances en groupe (4 enfants maxi)</td>
                <td>150€ les 4 séances</td>
            </tr>
            <tr>
                <td>Professionnels</td>
                <td>Sur devis</td>
            </tr>
        </table>
        <!-- </div> -->
    </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
table td {
    padding: 1rem 2rem;
}
table.bg-sand tr:nth-child(even) {
    background: rgb(205, 165, 113, 0.3);
}
table.bg-sand tr:nth-child(odd) {
    background: rgb(182, 189, 208, 0.3);
}
</style>
