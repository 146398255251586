<template>
    <div class="bg-logo">
        <h1 class="page-title">Le massage</h1>
        <br />
        <v-tabs vertical class="transparent d-none d-lg-flex">
            <v-tab> {{ massageTexts.part1.title }} </v-tab>
            <v-tab> {{ massageTexts.part2.title }} </v-tab>
            <v-tab> {{ massageTexts.part3.title }} </v-tab>
            <v-tab-item>
                <v-card flat>
                    <v-card-text class="text-left">
                        <h1 class="text-center">
                            {{ massageTexts.part1.title }}
                        </h1>
                        <br />
                        <div v-html="massageTexts.part1.content"></div>
                        <br />
                        <div class="d-flex d-lg-none justify-center">
                            <img src="../assets/img-6-small.jpg" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text class="text-left">
                        <h1 class="text-center">
                            {{ massageTexts.part2.title }}
                        </h1>
                        <br />
                        <div v-html="massageTexts.part2.content"></div>
                        <br />
                        <div class="d-flex d-lg-none justify-center">
                            <img src="../assets/img-1-small.jpg" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
            <v-tab-item>
                <v-card flat>
                    <v-card-text class="text-left">
                        <h1 class="text-center">
                            {{ massageTexts.part3.title }}
                        </h1>
                        <br />
                        <div v-html="massageTexts.part3.content"></div>
                        <br />
                        <div class="d-flex d-lg-none justify-center">
                            <img src="../assets/img-4-small.jpg" />
                        </div>
                    </v-card-text>
                </v-card>
            </v-tab-item>
        </v-tabs>

        <v-expansion-panels class="d-flex d-lg-none">
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{ massageTexts.part1.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    v-html="massageTexts.part1.content"
                    class="text-left flex-column pa-2"
                >
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{ massageTexts.part2.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    v-html="massageTexts.part2.content"
                    class="text-left flex-column pa-2"
                >
                </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel>
                <v-expansion-panel-header>
                    {{ massageTexts.part3.title }}
                </v-expansion-panel-header>
                <v-expansion-panel-content
                    v-html="massageTexts.part3.content"
                    class="text-left flex-column pa-2"
                >
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
import texts from '../assets/texts';
export default {
    data() {
        return {
            massageTexts: texts.massage,
        };
    },
};
</script>

<style scoped>
.img1 {
    width: 200px;
    position: fixed;
    top: 60%;
    left: 11%;
    transform: rotate(-5deg);
}
.img4 {
    width: 250px;
    position: fixed;
    top: 62%;
    left: 9%;
    transform: rotate(-8deg);
}
.img6 {
    width: 300px;
    position: fixed;
    top: 61%;
    left: 50%;
}
</style>
