import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '../views/Home.vue';
import AboutMe from '../views/AboutMe.vue';
import AboutMassage from '../views/AboutMassage.vue';
import Prices from '../views/Prices.vue';
import GiftCard from '../views/GiftCard.vue';
import Workshops from '../views/Workshops.vue';
import ContactMe from '../views/ContactMe.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Accueil',
        component: Home,
    },
    {
        path: '/qui-suis-je',
        name: 'Qui suis-je ?',
        component: AboutMe,
    },
    {
        path: '/a-propos-du-massage',
        name: 'A propos du massage',
        component: AboutMassage,
    },
    {
        path: '/ateliers',
        name: 'Les ateliers',
        component: Workshops,
    },
    {
        path: '/tarifs',
        name: 'Les tarifs',
        component: Prices,
    },
    {
        path: '/carte-cadeau',
        name: 'Carte cadeau',
        component: GiftCard,
    },
    {
        path: '/contact',
        name: 'Contactez-moi',
        component: ContactMe,
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
});

export default router;
